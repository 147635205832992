(function ($) {
  let animationsDisabled = 'with-animation';
  const selectorDisableAnimations = '.toggle-accessibility-animation';

  function getMenuSelectedAnimation(checkedElement) {
    if ($(checkedElement).prop('checked')) {
      return 'no-animation';
    }
    return 'with-animation';
  }

  function setMenuSelectedAnimation() {
    if ('no-animation' === animationsDisabled) {
      $.each($(selectorDisableAnimations), function (i, toggle) {
        $(toggle).prop('checked', true);
      });
    }
  }

  function updateAnimationClassInHtml() {
    if ('no-animation' === animationsDisabled) {
      $('html').addClass('no-animation');
    } else {
      $('html').removeClass('no-animation');
    }
  }

  function saveUserAnimationsControl() {
    localStorage.setItem('animationsDisabled', animationsDisabled);
  }
  function loadSavedUserAnimationsControl() {
    animationsDisabled =
      null !== localStorage.getItem('animationsDisabled')
        ? localStorage.getItem('animationsDisabled')
        : animationsDisabled;
  }

  function initGlobalAnimationsControl() {
    setMenuSelectedAnimation(animationsDisabled);
    $(selectorDisableAnimations).on('change', function (e) {
      animationsDisabled = getMenuSelectedAnimation(e.currentTarget);
      saveUserAnimationsControl();
      updateAnimationClassInHtml();
    });
  }

  // Early call to restore the state from the LocalStorage
  loadSavedUserAnimationsControl();
  updateAnimationClassInHtml();

  window.addEventListener('DOMContentLoaded', function () {
    initGlobalAnimationsControl();
  });
})(jQuery); // end conflict sage jQuery
